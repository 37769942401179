import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/baisc/Login.vue'
import Layout from '../../src/layouts/Layout.vue'
import Live from '../views/main/Live.vue' 
import Vehicle from '../views/main/Vehicle.vue' 
// import TankMeasurement from '../views/main/TankMeasurement.vue' 
// import TankMeasurementList from '../views/main/TankMeasurementList.vue' 
// import Display from '../views/main/Display.vue'
// import Home from '../views/main/Home.vue'
import store from '../store'
const routes = [
	{
		path: '', // 如果沒有填入路徑，元件Login會是一級路由的預設
		name: 'login',
		component: Login
	},
	{
		path: "/",
		name: "layout",
		component: Layout,
		children: [ 
			{
				path: "/5g-live",
				name: "live",
				component: Live,
				linkActiveClass: "active",
				meta: { requiresAuth: true } // 需要驗證登入
			}, 
			{
				path: "/5g-vehicle",
				name: "vehicle",
				component: Vehicle,
				linkActiveClass: "active",
				meta: { requiresAuth: true } // 需要驗證登入
			}, 
			// {
			// 	path: "/home",
			// 	name: "home", 
			// 	component: Home,
			// 	meta: { requiresAuth: true } // 需要驗證登入
			// }, 
			// {
			// 	path: "/display",
			// 	name: "display",
			// 	component: Display,
			// 	linkActiveClass: "active",
			// 	meta: { requiresAuth: true } // 需要驗證登入
			// },
			// {
			// 	path: "/tank-measurement",
			// 	name: "TankMeasurement",
			// 	component: TankMeasurement,
			// 	linkActiveClass: "active",
			// 	meta: { requiresAuth: true } // 需要驗證登入
			// }, 
			// {
			// 	path: "/tank-measurement-list",
			// 	name: "TankMeasurementList",
			// 	component: TankMeasurementList,
			// 	linkActiveClass: "active",
			// 	meta: { requiresAuth: true } // 需要驗證登入
			// }, 
			// {
			// 	path: "/url/04",
			// 	name: "page04", 
			// 	linkActiveClass: "active",
			// 	meta: { requiresAuth: true } // 需要驗證登入
			// }
		]
	}
];

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

// 假設有一個方法用於驗證TOKEN的有效性，可以根據您的需求進行修改
function isTokenValid() {
	// 在此處添加您的驗證TOKEN的邏輯，例如檢查TOKEN是否有效
	// 返回 true 或 false，表示TOKEN是否有效
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	store.state.userInfo = userInfo; 
	const token = userInfo.token || store.state.token;
	if (userInfo.token) store.commit('SetLoginToken',userInfo.token);

	if (token)
		return true;
	else
		return false;
}

router.beforeEach((to, from, next) => {
	//auto login
	if(to.path == "/" && to.query.acc  && to.query.psw  ){
		store.dispatch('GetLoginToken',{
			email: to.query.acc ,
			password:  to.query.psw 
		})
	}
	
	if (to.matched.some(route => route.meta.requiresAuth)) {
		// 需要驗證TOKEN
		if (isTokenValid()) {
			// TOKEN有效，允許訪問該頁面 
			next();
		} else {
			// TOKEN無效，導向登入頁面 
			next('/');
		}
	} else {
		// 不需要驗證TOKEN，允許訪問該頁面
		next();
	}
})
export default router
