import { createStore } from 'vuex'
import axios from 'axios';
import router from '../router'
var _ts=1697856638.8483468;
var API_URL = {
  login:"/login",
  logout:"/logout",
  resetPassword:"/reset-password",
  navigationItem:"/navigation-item/list", 
  orgList:"/organization/list",
  areaList:"/area/list",
  tankList:"/tank/list",
  tankMeasurementList:"tank-measurement/list",
  tankMeasurementImport:"tank-measurement/import",
  tankMeasurementDelete:"tank-measurement/delete",
  storageFile:"/storage/file",

  // gas-detection-car
  gas:{
    event:'/gas-detection-car/event',
    odometry:'/gas-detection-car/odometry', 
    position:'/gas-detection-car/position', 
    status:'/gas-detection-car/status', 
    cameraFlia:'/gas-detection-car/camera-flia',  
    camera360:'/gas-detection-car/camera360', 
  },
  dcs:{
    list:"/dcs/list"
  }
}
export default createStore({
  state: {
    loading:false,
    DOMAIN_URL :'', 
    userInfo:{
      token:"",
      email:"",
      password:"",
      name:"", 
    },
    generatePassword:{},
    loginInfo:{},
    token: '', 
    sideBar: [], 
    ChangePswInfo:{}, 
    displayBase64Img:null,  
    tankBase64Img:null,  
    tankMeasurementFileBase64:null,   
    org:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],    
    },
    area:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],    
    },
    tank:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],    
    },
    tankMeasurement:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],
      import:[]    
    }, 
    gas:{
      event:[],
      odometry:[], 
      position:[], 
      status:[], 
      cameraFlia:[],  
      camera360:[], 
    },
    dcs:{
      list:[]
    }
  },
  mutations: {
    SetLoginInfo(state,payload){
      state.loginInfo = payload;  
    },
    SetLoginToken(state,payload) {
      state.token = payload;  
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));  
      localStorage.setItem("role", JSON.stringify(state.role));  
      this.dispatch('GetSideBar');
    },
    DeleteLoginToken(state) {
      state.userInfo.token="";
      state.userInfo.password="";
      state.userInfo.role="";
      state.token = "";
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo)); 
      router.push({ name: 'login' })
    },
    SetSideBar(state, payload) {   
      state.sideBar = payload;
      // this.dispatch('GetAllPermissions');
    },
    SetChangePsw(state, payload) { 
      state.ChangePswInfo = payload;//監聽並關閉視窗
    },  
    /*--dropDown--*/
    SetDropDownOrgList(state, payload) {
      state.org.dropDown = payload;
    }, 
    SetDropDownAreaList(state, payload) {
      state.area.dropDown = payload;
    }, 
    SetDropDownTankList(state, payload) {
      state.tank.dropDown = payload;
    },  
    SetSearchTankMeasurementList(state, payload) {
      state.tankMeasurement.search = payload; 
    },  
    SetImportTankMeasurement(state, payload) { 
      state.tankMeasurement.import = payload; 
    },  
    SetDeleteTankMeasurement(state, payload) { 
      state.tankMeasurement.delete = payload; 
    },  
    SetDisplayBase64Img(state, payload) { 
      state.displayBase64Img = payload; 
    },  
    SetTankBase64Img(state, payload) { 
      state.tankBase64Img = payload; 
    },  
    SetTankMeasurementFileBase64(state, payload) { 
      state.tankMeasurementFileBase64 = payload; 
    },  
    ShowLoaderPanel(state, payload) {
      state.loading= payload; 
    }, 
    /*--5g--*/ 
      SetGasEvent(state, payload) {
        state.gas.event = payload;
      },  
      SetGasOdometry(state, payload) {
        state.gas.odometry = payload;
      },  
      SetGasPosition(state, payload) {
        state.gas.position = payload;
      },  
      SetGasStatus(state, payload) {
        state.gas.status = payload;
      }, 
      SetGasCameraFlia(state, payload) {
        state.gas.cameraFlia = payload;
      }, 
      SetGasCamera360(state, payload) {
        state.gas.camera360 = payload;
      }, 
      SetDcsList(state, payload) {
        state.dcs.list = payload;
      }, 
  },
  getters: {
    // CheckPermissions: (state) => (key) => { 
    //   for(var i in state.allPermissions){
    //     if(state.allPermissions[i].name==key)return true;
    //   }
    //   return false; 
    // }
  },
  actions: {  
    GetLoginToken({ commit }, payload) {
      axios.post(API_URL.login,
        {
          "email": payload.email,
          "g-recaptcha-response": payload['g-recaptcha-response'],
          "password": payload.password
        }
      ).then(result => { 
        commit('SetLoginInfo',result.data); 
        if (result.data.header.message == "Success") {
          this.state.userInfo.password=payload.saveToken? payload.password:"";
          this.state.userInfo.token=result.data.data.access_tokens;
          this.state.userInfo.name=result.data.data.name;
          this.state.userInfo.email=payload.email;  
          this.state.userInfo.role=result.data.data.role;  

          commit('SetLoginToken',result.data.data.access_tokens); 
          console.log(router.currentRoute.value.query.factory)

          router.push({ name: 'live' ,query:{factory:router.currentRoute.value.query.factory?router.currentRoute.value.query.factory:''}})
        }

      }).catch(error => {
        throw new Error(`API ${error}`);
      });
    },
    GetSideBar({ commit }) { 
      axios.post(API_URL.navigationItem,
        {
          "page": 1,
          "count": 100,
          "sort_field": "id",
          "sort_order": "asc",
          "is_output": 0
        }, {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => {  
          if (result.data.header.message == "Success") {
            commit('SetSideBar', result.data.data.list);
          }
        }).catch(error => {
          this.dispatch('Logout');
          throw new Error(`API ${error}`);
        });
    },
    Logout({ commit }) { 
      axios.post(API_URL.logout,
        {}, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => {
          result;
        }).catch(error => {
          throw new Error(`API ${error}`);
        }); 
        commit('DeleteLoginToken');
    },
    ChangePsw({commit},payload){
      commit('SetChangePsw', '');
      axios.post(API_URL.resetPassword,
        payload, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      }
      ).then(result => { 
        if (result.data.header.message == "Success") {   
          if(this.state.userInfo.password){
            this.state.userInfo.password = payload.password;
            localStorage.setItem("userInfo", JSON.stringify(this.state.userInfo)); 
          }  
        } 
        commit('SetChangePsw', result); 
      }).catch(error => {
        throw new Error(`API ${error}`);
      });
    }, 
    /*--dropDown--*/
    GetDropDownOrgList({ commit },payload) { 
      axios.post(API_URL.orgList,
        {
          "page": 1,
          "count": 10,
          "sort_field": "id",
          "sort_order": "desc",
          "search" : payload,
          "is_dropdown":1,
          "is_output": 0
      }, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => { 
          if (result.data.header.message == "Success") {
            commit('SetDropDownOrgList',result.data.data.list);
          }
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    },
    GetDropDownAreaList({ commit },payload) {
      axios.post(API_URL.areaList,
        {
          "page": 1,
          "count": 10,
          "sort_field": "id",
          "sort_order": "desc",
          "search" : payload,
          "is_dropdown":1,
          "is_output": 0
      }, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => { 
          if (result.data.header.message == "Success") {
            commit('SetDropDownAreaList',result.data.data.list);
          }
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    },
    GetDropDownTankList({ commit },payload) {
      axios.post(API_URL.tankList,
        {
          "page": 1,
          "count": 10,
          "sort_field": "id",
          "sort_order": "desc",
          "search" : payload,
          "is_dropdown":1,
          "is_output": 0
      }, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => { 
          if (result.data.header.message == "Success") {
            commit('SetDropDownTankList',result.data.data.list);
          }
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    }, 
    /*--search--*/
    GetSearchTankMeasurementList({ commit },payload) { 
      // commit('SetSearchDisplayList',{});
      axios.post(API_URL.tankMeasurementList, payload, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => { 
          if (result.data.header.message == "Success") {
            commit('SetSearchTankMeasurementList',result.data.data);
          }
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    }, 
    GetImportTankMeasurement({ commit },payload) {
      commit('ShowLoaderPanel',true); 
      axios.post(API_URL.tankMeasurementImport, payload,
        {
          timeout: 1000*60*60*24,
          headers: 
          {
            'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {     
            commit('SetImportTankMeasurement',result);  
            commit('ShowLoaderPanel',false); 
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetDeleteTankMeasurement({ commit },payload) {
      axios.post(API_URL.tankMeasurementDelete , payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetDeleteTankMeasurement',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    GetDisplayBase64Img({ commit },payload) {  
      commit('SetDisplayBase64Img',null);
      axios.post(API_URL.storageFile,
        {
          "folder": "tankMeasurementDetail",
          "filename": payload
      }, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => {  
            commit('SetDisplayBase64Img',result.data); 
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    },  
    GetTankBase64Img({ commit },payload) {  
      commit('SetTankBase64Img',null);
      axios.post(API_URL.storageFile,
        {
          "folder": "tanks",
          "filename": payload
      }, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => {  
            commit('SetTankBase64Img',result.data); 
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    },  
    GetTankMeasurementFileBase64({ commit },payload) {  
      commit('SetTankMeasurementFileBase64',null);
      axios.post(API_URL.storageFile,
        {
          "folder": "tankMeasurements",
          "filename": payload
      }, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => {  
            commit('SetTankMeasurementFileBase64',result.data); 
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    },
    /*--5g--*/ 
    GetGasEvent({ commit }) { 
      axios.post(API_URL.gas.event,
        {     
          "ts": _ts 
        }, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}`  
        }
      })
        .then(result => { 
          if (result.data.header.message == "Success") {
            var _data = result.data.data.data;
            commit('SetGasEvent', _data[_data.length-1] );
          }
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    },
    GetGasOdometry({ commit }) { 
      axios.post(API_URL.gas.odometry,
        {     
          "ts": _ts 
        }, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}`  
        }
      })
        .then(result => { 
          if (result.data.header.message == "Success") { 
            var _data = result.data.data.data;
            commit('SetGasOdometry', _data[_data.length-1] );
          }
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    },
    GetGasPosition({ commit }) { 
      axios.post(API_URL.gas.position,
        {     
          "ts": _ts 
        }, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}`  
        }
      })
        .then(result => { 
          if (result.data.header.message == "Success") { 
            var _data = result.data.data.data;
            commit('SetGasPosition', _data[_data.length-1] );
          }
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    },
    GetGasStatus({ commit }) { 
      axios.post(API_URL.gas.status,
        {     
          "ts": _ts 
        }, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}`  
        }
      })
        .then(result => { 
          if (result.data.header.message == "Success") { 
            var _data = result.data.data.data;
            commit('SetGasStatus', _data[_data.length-1] );
          }
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    },
    GetGasCameraFlia({ commit }) { 
      axios.post(API_URL.gas.cameraFlia,
        {     
          "ts": _ts
        }, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}`  
        }
      })
        .then(result => { 
          if (result.data.header.message == "Success") { 
            var _data = result.data.data.image;
            commit('SetGasCameraFlia', _data );
          }
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    },
    GetGasCamera360({ commit }) { 
      axios.post(API_URL.gas.camera360,
        {     
          "ts": _ts 
        }, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}`  
        }
      })
        .then(result => { 
          if (result.data.header.message == "Success") { 
            var _data = result.data.data.image; 
            commit('SetGasCamera360', _data  );
          }
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    },
    GetDcsList({ commit }) { 
      axios.post( API_URL.dcs.list ,
        {
          "page": 1,
          "count": 10,
          "sort_field": "id",
          "sort_order": "desc",
          "is_output": 0
        }, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}`  
        }
      })
        .then(result => { 
          if (result.data.header.message == "Success") {
            commit('SetDcsList',result.data.data.list);
          }
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
    },
  },
  modules: {
  }
})
