<template>
    <div class="container-fluid">
        <div v-if="!view_isDcs" class="row  px-2 pt-2">
            <div class="col-lg-9 col-8 ">
                <div class="row ">
                    <div class="col-12 ">
                        <div class="white-board">
                            <div class="white-board-title">巡檢即時資料</div>
                            <div class="canvas-wrap-5G">
                                <canvas width="1720" height="1080" id="three-5G"></canvas>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 ">
                        <div class="white-board">
                            <div class="white-board-title">Flir a400</div>
                            <img v-if="GasCameraFlia" :src="'data:image/jpeg;base64, ' + GasCameraFlia">
                            <p class="noPictures" v-if="!GasCameraFlia">No pictures yet</p>
                        </div>
                    </div>
                    <div class="col-6 ">
                        <div class="white-board">
                            <div class="white-board-title">360環景</div>
                            <img v-if="GasCamera360" :src="'data:image/jpeg;base64, ' + GasCamera360">
                            <p class="noPictures" v-if="!GasCamera360">No pictures yet</p>
                        </div>
                    </div>
                    <div class="col-12">
                        <button @click="simulate()">simulate</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-4 ">
                <div class="white-board">
                    <div class="white-board-title">DCS即時資料</div>
                    <div class="dcs-list" v-for="dcs in DcsList" :key="dcs.id">
                        <div class="dcs-list-title">{{ dcs.sensor }}</div>
                        <div class="dcs-list-info">
                            <span class="dcs-list-info-value">{{ dcs.value }}</span>
                            <span class="dcs-list-info-unit">{{ dcs.unit }} </span>
                        </div>
                    </div>
                    <!-- <button class="search-btn" @click="view_isDcs = true" style="
                        padding: 3px 15px;
                        width: 100%;
                        height: 35px;
                        letter-spacing: .075em;
                        font-weight: 500;">
                        回DCS主頁面
                    </button> -->
                    <button class="search-btn" onclick="location.href='https://sis-dev.ioc.xadm.tw/'" style="
                        padding: 3px 15px;
                        width: 100%;
                        height: 35px;
                        letter-spacing: .075em;
                        font-weight: 500;">
                        回到雲平台
                    </button>
                </div>
            </div>
        </div>
        <div v-if="view_isDcs" class="row  px-2 pt-2">
            <!-- {{ DcsList }} -->
            <div class="dcs-view">
                <img class="dcs-view-img" src="/dcs.png" />
                <template v-for="_d in DcsList" :key="_d.id">
                    <span v-bind:class="_d.sensor" v-if="dcs.indexOf(_d.sensor) != -1">{{ _d.value + " " + _d.unit
                        }}</span>
                </template>
                <!-- 
                <span class="TT-206">TT-206</span>
                <span class="LI-203">LI-203</span>
                <span class="TI-260A">TI-260A</span>
                <span class="PI-260A">PI-260A</span>
                <span class="LCV-284">LCV-284</span>
                <span class="PI-260B">PI-260B</span>
                <span class="LI-260">LI-260</span>
                <span class="LIC-260">LIC-260</span>
                <span class="LI-262">LI-262</span> 
                -->
            </div>
            <button class="search-btn" @click="view_isDcs = false" style="
                padding: 3px 15px;
                width: 180px;
                height: 35px;
                letter-spacing: 0.075em;
                font-weight: 500;
                margin-top: 10px;
                margin-bottom: 30px;">
                回巡檢主頁面
            </button>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import * as THREE from 'three'
// import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js'
// import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
// import { ThreeMFLoader } from 'three/examples/jsm/loaders/3MFLoader.js'
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

// import { MTLLoader, OBJLoader } from "three-obj-mtl-loader";
export default {
    searchUserList: {},
    name: 'live',
    data() {
        return {
            dcs: ["TT-206", "LI-203", "TI-260A", "PI-260A", "LCV-284", "PI-260B", "LI-260", "LIC-260", "LI-262", "P-263A"],
            view_isDcs: false,
            stop: false,
        };
    },
    watch: {
        view_isDcs: function (val) {
            if (val == false) {
                this.stop = false;
                var self = this;
                setTimeout(function () {
                    self.initThree();
                }, 50)
            } else {
                this.stop = true;
            }
        }

    },
    mounted() {
        this.initThree()
    },
    computed: {
        ...mapState(
            {
                GasEvent: state => state.gas.event,
                GasOdometry: state => state.gas.odometry,
                GasPosition: state => state.gas.position,
                GasStatus: state => state.gas.status,
                GasCameraFlia: state => state.gas.cameraFlia,
                GasCamera360: state => state.gas.camera360,
                DcsList: state => state.dcs.list,
            }
        ),
    },
    methods: {
        ...mapActions([
            'GetDropDownOrgList',
            'GetDropDownAreaList',
            'GetDropDownTankList',
            'GetSearchDisplayList',
        ]),
        simulate() {
            if (this.GasPosition) {
                this.GasPosition.x -= 5;
            }
        }
        ,
        initThree() {
            var self = this;
            const scene = new THREE.Scene()
            scene.background = new THREE.Color('#999')
            const canvas = document.querySelector('#three-5G')
            const renderer = new THREE.WebGLRenderer({ canvas, antialias: true })
            renderer.premultipliedAlpha = false;
            renderer.setSize(window.innerWidth, window.innerHeight);
            renderer.outputEncoding = THREE.sRGBEncoding;
            // renderer.physicallyCorrectLights = true;
            // renderer.setPixelRatio(window.devicePixelRatio);
            const camera = new THREE.PerspectiveCamera(
                20,
                window.innerWidth / window.innerHeight,
                1,
                10000
            )
            camera.position.setZ(0);
            camera.position.setY(540);
            camera.position.setX(0);
            // scene.position.setY(100);

            /*----光源----*/
            const pointLight = new THREE.PointLight(0xfffffff);
            const ambientLight = new THREE.AmbientLight(0xfffffff);
            pointLight.position.set(20, 5, 5);
            // scene.add(pointLight, ambientLight); 
            /*----光源輔助----*/
            //const lightHelper = new THREE.PointLightHelper(pointLight); 
            /*----網格輔助----*/
            // const gridHelper = new THREE.GridHelper(500, 20);
            // scene.add(lightHelper, gridHelper); 
            /*----滑鼠控制----*/
            const controls = new OrbitControls(camera, renderer.domElement);
            scene.add(pointLight, ambientLight);
            // scene.add(gridHelper); 
            /*----PolarGridHelper----*/
            const radius = 50;
            const radials = 4;
            const circles = 5;
            const divisions = 64;
            const helper = new THREE.PolarGridHelper(radius, radials, circles, divisions, "#666", "#444");
            scene.add(helper);
            /*----Light----*/
            const keyLight = new THREE.DirectionalLight(
                350,
                1
            );
            keyLight.position.set(-100, 0, 200);
            const fillLight = new THREE.DirectionalLight(
                new THREE.Color('hsl(240, 100%, 75%)'),
                0.75
            );
            fillLight.position.set(100, 0, -300);
            const backLight = new THREE.DirectionalLight(0xffffff, 1.0);
            backLight.position.set(100, 0, -100).normalize();
            scene.add(keyLight, fillLight, backLight);
            // const helper1 = new THREE.DirectionalLightHelper(keyLight, 100);
            // const helper2 = new THREE.DirectionalLightHelper(fillLight, 100);
            // const helper3 = new THREE.DirectionalLightHelper(backLight, 100);
            // scene.add(  helper1, helper2, helper3);

            /*----3MFLoader----*/
            // var loader2 = new ThreeMFLoader();
            // loader2.load('./car.3MF', function (object) {
            //     console.log(object);
            //     object.quaternion.setFromEuler(new THREE.Euler(-Math.PI / 2, 0, 0));
            //     object.traverse(function (child) {
            //         if (child.isMesh) { 
            //             console.log(child);
            //             scene.add(child);
            //         }
            //     });
            // });
            /*----arrow----*/
            const arrow = new THREE.ConeGeometry(1, 3.5, 30);
            const arrowMaterial = new THREE.MeshBasicMaterial({ color: "red" });
            const cone = new THREE.Mesh(arrow, arrowMaterial);
            cone.position.setZ(0);
            cone.position.setY(0);
            cone.position.setX(0);
            // cone.rotateX(2 * Math.PI * (90 / 360));
            // cone.rotateZ(2 * Math.PI * (90 / 360))
            scene.add(cone)

            /*----3D----*/
            var pdcLoadeer = new PCDLoader();
            pdcLoadeer.setPath('./');
            pdcLoadeer.load(
                // resource URL
                (this.$router.currentRoute.value.query.factory && this.$router.currentRoute.value.query.factory == 2)
                    ?
                    './5G_2.pcd' : './5G.pcd',
                // called when the resource is loaded
                function (points) {
                    scene.add(points);
                },
                // called when loading is in progresses
                function (xhr) {
                    console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                    animate();
                },
                // called when loading has errors
                function (error) {
                    console.log(error, 'An error happened');
                }
            );

            function animate() {
                //if isChange數值有更動 dotReload 
                if (self.GasPosition) {
                    var _p = self.GasPosition;
                    cone.position.setZ(_p.y);
                    cone.position.setX(_p.x);
                    cone.rotation.set(0, 0, 2 * Math.PI * (90 / 360));
                    cone.rotateX(2 * Math.PI * ((180 / Math.PI * _p.theta) / 360))
                }
                renderer.render(scene, camera)
                if (!self.stop) requestAnimationFrame(animate);
                controls.update()
            }
        },
    }
}
</script>